<template>
  <div class="channel-settings">
    <b-card>
      <template #header>
        <h6 class="mb-0">How to use</h6>
      </template>
      <b-card-text>
        <p>In order to be able to receive calls in a channel you need to:</p>
        1) add your website domain (or subdomain) in the <b-link :to="{name: 'callcenter.channel.origins', params: {channelId: channel.id}}">origins section</b-link>,<br>
        2) <b-link :to="{name: 'widgets.widget.create'}">create a bubble widget</b-link> for this channel,<br>
        3) embed the generated code (HTML) in your website.
      </b-card-text>
    </b-card>

    <b-card no-body class="card-custom mt-3">
      <template #header>
        <b-button v-if="user.hasPermission('rtc:widget.create')" class="float-right" size="sm" variant="primary" :to='{name: "widgets.widget.create"}'>
          <BIconPlus/> New Widget
        </b-button>
        <h6 class="mb-0">Channel widgets</h6>
      </template>
      <b-card-text>
        <div class="table-custom">
          <WidgetList :user="user" :channel="channel"/>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {BIconPlus} from 'bootstrap-vue';
import WidgetList from './../../../components/Widget/Widget.List';
export default {
    props: ['channel','user'],
    components: {
      WidgetList,
      BIconPlus
    },

    mounted(){
      this.$emit('set-channel',{activeSidebar: "integration"});
    }
};
</script>
