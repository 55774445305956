<template>
  <div class="widget-list">
    <b-table v-if="!widgets.isLoading" responsive hover :items="widgets.records" :fields="widgets.fields" empty-text="no widgets" show-empty>
      <template #cell(options)="data">
        <div class="text-right">
          <b-link v-if="user.hasPermission('rtc:widget.update')" :to='{name: "widgets.widget.edit", params: {widgetId: data.item.id}}' v-b-tooltip.hover title="Edit"><BIconPencilSquare/></b-link>
        </div>
      </template>
      <template #cell(channelId)="data">
        <b-link :to="{name: 'channel.board', params: {channelId: data.item.channelId}}">{{ getChannelName(data.item.channelId) }}</b-link>
      </template>
    </b-table>
    <div v-else style="padding: 20px;">
      <b-spinner label="Loading..." small></b-spinner>
    </div>
  </div>
</template>

<script>
import {BIconPencilSquare} from 'bootstrap-vue';
export default {
    props: ['user','channel'],
    components: {
      BIconPencilSquare
    },
    data(){
        return {
          widgets: {
            isLoading: false,
            fields: [],
            records: [],
            total: 0,
            pagination: {
              currentPageNo: 1
            }
          },
          languages: [
            {locale: "en", name: "English"},
            {locale: "de", name: "Deutsch"},
          ]
        };
    },

    methods: {
      getChannelName(id){
        let channel = this.channels.find(o => o.id === id);
        if(!channel){
          return id;
        }
        return channel.name;
      },
      getFields(){
        let fields = [
          {key: 'id', label: "ID"},
          {key: 'name',label: "Name"},
          {key: 'channelId',label: "Channel"},
          {
            key: 'locale',
            label: "Language",
            formatter: locale => {
              let lang = this.languages.find(o => o.locale === locale);
              return lang ? lang.name : locale;
            }
          },
        ];

        fields.push({key: 'options',label: ""});
        return fields;
      },
      getWidgets(){
        this.widgets.isLoading = true;
        let params = {perPage: 12};
        if(this.channel){
          params.channel = this.channel.id;
        }
        return this.$api.get('widgets',{params: params}).then(res => {
          this.widgets.isLoading = false;
          let {records, pagination, total} = res.data;
          this.widgets.records = records;
          this.widgets.pagination = pagination;
          this.widgets.total = total;
          this.widgets.fields = this.getFields();
        }).catch(() => {
          console.log('cannot fetch widgets');
          this.widgets.isLoading = false;
        });
      }
    },

    mounted(){
      this.getWidgets();
    },

    computed: {
      channels(){
        return this.$store.getters['Callcenter/getChannels'];
      }
    }
};
</script>
